/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, List, Image, Subtitle, PriceWrap, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1su0bdf css-47wik6 --style3 js-anim  --anim7 --anim-s5 --full pb--10" anim={"7"} name={"uvod"} animS={"5"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38199/d47b174659594116b8baa2d3d2ed09a0_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38199/d47b174659594116b8baa2d3d2ed09a0_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38199/d47b174659594116b8baa2d3d2ed09a0_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38199/d47b174659594116b8baa2d3d2ed09a0_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38199/d47b174659594116b8baa2d3d2ed09a0_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38199/d47b174659594116b8baa2d3d2ed09a0_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38199/d47b174659594116b8baa2d3d2ed09a0_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38199/d47b174659594116b8baa2d3d2ed09a0_s=3000x_.jpeg);
    }
  
background-position: 55% 49%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5" anim={"6"} animS={"5"}>
              
              <Title className="title-box fs--62 w--600 lh--1 pb--20" style={{"maxWidth":855}} content={"<span style=\"color: var(--color-custom-1--95);\">Otevírání zamčených vozidel<br>a mobilní výroba autoklíčů<br></span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape3 fs--20" content={"<span style=\"font-weight: bold;\">NABÍDKA SLUŽEB</span>"} use={"page"} href={"/#sluzby"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"rgba(32, 32, 32, 1)"}} name={"kontakty"}>
          
          <ColumnWrap className="column__flex --center el--2 pb--20 pt--20" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box fs--30" content={"<a href=\"tel:+420731731751\">+420 731 731 751</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box fs--30" content={"<a href=\"mailto:klicmistr@gmail.com?subject=DOTAZ+Z+KLICMISTR.CZ\">klicmistr@gmail.com</a>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 --anim-s3 pb--20 pt--20" name={"sluzby"} anim={"2"} animS={"3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s2" anim={"2"} animS={"2"}>
              
              <Title className="title-box" content={"Odemykání zamčených vozidel a<br>&nbsp;mobilní výroba autoklíčů&nbsp;v Kutné Hoře a okolí<br>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":1000}} content={"<span style=\"font-weight: bold;\">Odemčení zamčeného vozidla nebo mobilní výroba autoklíče v Kutné Hoře a širokém okolí</span> (+30 km)?<br>Odemkneme až 95 % všech značek vozidel na trhu. Dále se zabýváme <span style=\"font-weight: bold;\">výrobou autoklíčů i s dálkovým ovládáním</span>, jejich naprogramováním a <span style=\"font-weight: bold;\">spárováním s vaším vozidlem</span>. Umíme také řezat planžety autoklíčů a bez problémů vyměníme baterie<br>v dálkových ovladačích.<br><br>Díky našim dlouholetým zkušenostem vám dále nabízíme <span style=\"font-weight: bold;\">diagnostiku vozidel a softwarové úpravy,&nbsp;</span>jako je deaktivace Start-Stop <br>na vozidlech VW, Škoda, Audi a Seat. Poradíme si také s opravou kilometrů například po výměně přístrojové desky.<br><br>Každý servisní zásah je prováděn zkušeným technikem výhradně za pomoci nejmodernějších technologií a zařízení. To vše <span style=\"font-weight: bold;\">non-stop</span><br><span style=\"font-weight: bold;\">&nbsp; s naší servisní pojízdnou dílnou přímo na vaší adrese!</span> Naše služby jsou rychlé, férové, pohodlné a efektivní.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--3 mt--16 flex--center" style={{"maxWidth":1000}} anim={"2"} animS={"2"} columns={"3"}>
            
            <ColumnWrapper >
              
              <List className="text-box text-box--left" style={{"maxWidth":331}} subtype={"unordered"} content={"<li><span style=\"background-color: transparent; color: var(--text-color); letter-spacing: var(--text-tracking); font-weight: bold;\">Odemykání zamčených vozidel</span></li><li><span style=\"background-color: transparent; color: var(--text-color); letter-spacing: var(--text-tracking); font-weight: bold;\">Výroba autoklíčů včetně dálkových ovladačů</span></li>"}>
              </List>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <List className="text-box text-box--left" style={{"maxWidth":330}} subtype={"unordered"} content={"<li><span style=\"background-color: transparent; color: var(--text-color); letter-spacing: var(--text-tracking);\">Programování a spárování klíče</span></li><li>Výměna baterií v dálkových ovladačích</li>"}>
              </List>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <List className="text-box text-box--left" style={{"maxWidth":330}} subtype={"unordered"} content={"<li><span style=\"background-color: transparent; color: var(--text-color); letter-spacing: var(--text-tracking);\">Řezání planžet autoklíčů</span><br></li><li><span style=\"background-color: transparent; color: var(--text-color); letter-spacing: var(--text-tracking);\">Diagnostika automobilů</span></li><li><span style=\"background-color: transparent; color: var(--text-color); letter-spacing: var(--text-tracking);\">Softwarové úpravy</span></li>"}>
              </List>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--4 --full mb--10 mt--0 pr--08" style={{"maxWidth":1000}} anim={"2"} animS={"2"} columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":96}} alt={"Mobilní výroba klíčů"} src={"https://cdn.swbpg.com/o/38199/5077831ade564815af5e377680dd3a15.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" srcSet={""} svgColor={"rgba(177,37,37,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"PŘIJEDEME K VÁM"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":96}} alt={"Otevírání vozidel nonstop"} src={"https://cdn.swbpg.com/o/38199/929c8d55ca1c447eb9cc7d7193995ce0.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" srcSet={""} svgColor={"rgba(177,37,37,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"NON-STOP SERVIS"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":96}} alt={"Levné otevírání vozidel"} src={"https://cdn.swbpg.com/o/38199/9b4ef90a3b894aca93d7eeb87217d9cf.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" srcSet={""} svgColor={"rgba(177,37,37,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"FÉROVÉ CENY"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":96}} alt={"Osobní přístup"} src={"https://cdn.swbpg.com/o/38199/1840ba34fa7e4c18a78dee9777afc018.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" srcSet={""} svgColor={"rgba(177,37,37,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"OSOBNÍ PŘÍSTUP"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 --anim-s3 pt--20" style={{"backgroundColor":"rgba(19,64,148,1)"}} name={"ceny"} anim={"2"} animS={"3"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box lh--12" style={{"maxWidth":1000}} content={"KOLIK TO<br>BUDE STÁT?"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" style={{"maxWidth":820}} content={"<span style=\"color: var(--color-custom-1--95);\"><span style=\"font-weight: bold;\">Cenu u nás znáte předem!</span> <br>Nabízíme velmi férovou cenu za nadstandardní službu naší mobilní servisní pohotovosti.<br>Za naše služby lze <span style=\"font-weight: bold;\">platit hotově i platební kartou!</span><br>Uvedené ceny se mohou mírně lišit v závislosti na složitosti celé služby.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 --anim-s3 pb--20 pt--20" name={"cenik"} anim={"2"} animS={"3"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Odemčení vozidla r.v do 1998"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-custom-1); font-weight: bold;\">890,- Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Odemčení vozidla r.v do 2011"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-custom-1); font-weight: bold;\">1390,- Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Odemčení vozidla r.v od 2011"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">1890,- Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"&nbsp;Výroba autoklíče bez dálkového ovládání"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">1200-3000,- Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Výroba autoklíče s čipem a dálkovým ovládáním"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-custom-1); font-weight: bold;\">2000-6000,- Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Ostatní práce (každá započtená hodina)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">550,-/hodina</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Marný výjezd"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">1000,- Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 --anim-s3 pb--20" name={"priplatky"} layout={"l3"} anim={"2"} animS={"3"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--4" columns={"4"} anim={"2"} animS={"3"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"Příplatek za práci <br>18:00-07:00<br>"}>
              </Text>

              <Text className="text-box fs--20" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">+ 50 %</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"Příplatek za práci <br>ve státní svátky<br>"}>
              </Text>

              <Text className="text-box fs--20" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">+ 100 %</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"Sazba za km k zákazníkovi<br>mimo Kutnou Horu<br>"}>
              </Text>

              <Text className="text-box fs--20" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">+ 20 Kč / km</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"Příplatek za platbu<br>platební kartou<br>"}>
              </Text>

              <Text className="text-box fs--20" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">+ 0 Kč</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 --anim-s3 pb--20 pt--20" style={{"backgroundColor":"var(--color-custom-1)"}} name={"reference"} anim={"2"} animS={"3"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box lh--12" content={"<span style=\"color: var(--color-custom-2);\">NÁZORY NAŠICH ZÁKAZNÍKŮ</span>"}>
              </Title>

              <Button className="btn-box btn-box--pbtn3 btn-box--cColor2 btn-box--center" content={"Další recenze"} url={"https://www.google.com/maps/place/KL%C3%8D%C4%8C+MISTR+%7C+otev%C3%ADr%C3%A1n%C3%AD+zamknut%C3%BDch+vozidel/@49.8724049,15.2540759,9z/data=!4m8!3m7!1s0x6c02c2d040d9054d:0xfdcec8a2f8c716bd!8m2!3d49.8724049!4d15.2540759!9m1!1b1!16s%2Fg%2F11y4hz7bq7?entry=ttu"} href={"https://www.google.com/maps/place/KL%C3%8D%C4%8C+MISTR+%7C+otev%C3%ADr%C3%A1n%C3%AD+zamknut%C3%BDch+vozidel/@49.8724049,15.2540759,9z/data=!4m8!3m7!1s0x6c02c2d040d9054d:0xfdcec8a2f8c716bd!8m2!3d49.8724049!4d15.2540759!9m1!1b1!16s%2Fg%2F11y4hz7bq7?entry=ttu"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s2 --left" anim={"4"} animS={"2"}>
              
              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\">\"S Panem Setničkou vždy proběhlo vše dle domluvy, rychle za dobrou cenu, ale především kvalitně! To se dnes už moc nevidí. Rozhodně doporučuji.\"</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: white; font-weight: bold;\">—   Tomáš Fojt&nbsp; &nbsp; ⭐ ⭐ ⭐ ⭐ ⭐&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s2 --left" anim={"4"} animS={"2"}>
              
              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\">\"Kvalitně odvedená práce, velmi rychlý a vstřícný přístup. Vřele doporučuji.\"</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: white; font-weight: bold;\">—   Adam Bárta&nbsp; &nbsp; ⭐ ⭐ ⭐ ⭐ ⭐&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1eolcr1 css-1d07dg js-anim  --anim2 --anim-s3 pb--80 pt--80" name={"kontakt"} anim={"2"} animS={"3"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/38199/5548eebc8c334643a8fdd1894d267fe6_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/38199/5548eebc8c334643a8fdd1894d267fe6_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/38199/5548eebc8c334643a8fdd1894d267fe6_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/38199/5548eebc8c334643a8fdd1894d267fe6_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/38199/5548eebc8c334643a8fdd1894d267fe6_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/38199/5548eebc8c334643a8fdd1894d267fe6_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/38199/5548eebc8c334643a8fdd1894d267fe6_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/38199/5548eebc8c334643a8fdd1894d267fe6_s=3000x_.jpeg);
    }
  
background-position: 6% 50%;
    `}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--43" style={{"maxWidth":390}} content={"<a href=\"tel:+420731731751\">+420 731 731 751</a>"}>
              </Title>

              <Title className="title-box fs--43" style={{"maxWidth":390}} content={"<a href=\"mailto:klicmistr@gmail.com?subject=DOTAZ+Z+KLICMISTR.CZ\">klicmistr@gmail.com</a>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" name={"zxec2zq6sj"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 el--2 flex--center" columns={"2"} fullscreen={false} anim={"2"} animS={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box lh--12" content={"OBJEDNEJTE SI<br>NAŠE SLUŽBY<br>"}>
              </Title>

              <Text className="text-box lh--12" style={{"maxWidth":380}} content={"<span style=\"color: var(--color-blend--15);\">Vaše osobní údaje budou využity pouze pro potřeby vyřízení vaší poptávky služeb. Stisknutím \"Odeslat poptávku\" souhlasíte s výše uvedeným.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 --right fs--14" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"type":"text","name":"Adresa","placeholder":"Adresa"},{"type":"text","name":"Značka a typ automobilu","placeholder":"Značka a typ automobilu"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 --anim-s3 pb--10 pt--10" layout={"l12"} name={"certifikaty"} lightbox={true} anim={"2"} animS={"3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38199/7c6806ff44c1405bac11fa2d6ef149f0_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38199/7c6806ff44c1405bac11fa2d6ef149f0_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/38199/7c6806ff44c1405bac11fa2d6ef149f0_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38199/a4dbc8a40a2a4cc3aa9d34dc8c38b025_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38199/a4dbc8a40a2a4cc3aa9d34dc8c38b025_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/38199/a4dbc8a40a2a4cc3aa9d34dc8c38b025_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38199/2873f13ce2d747749de9ccd698b56e8e_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38199/2873f13ce2d747749de9ccd698b56e8e_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/38199/2873f13ce2d747749de9ccd698b56e8e_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(32, 32, 32, 1)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" style={{"maxWidth":1420}} anim={"2"} animS={"2"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-2);\">Kdo je KlíčMistr</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: rgb(243, 245, 250);\">Odemkneme vám zamknutý automobil, vyrobíme nový auto klíč i s dálkovým ovládáním <br>a napárováním. Snažíme se pravidelně vzdělávat a využívat těch <span style=\"font-weight: bold;\">nejpokročilejších technologií</span>, které nám umožní vyřešit problém až u 95 % automobilů. To vše s maximálním osobním přístupem, naprostou férovostí a dojezdem přímo na vaši adresu.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-2);\">Kde využijete našich služeb</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-custom-1--95);\">Využijte služeb mobilní dílny. Přijedeme vám <span style=\"font-weight: bold;\">otevřít zamknutý automobil</span> nebo vyrobit <span style=\"font-weight: bold;\">nový automobilový klíč se spárováním</span> kamkoliv <br>v okolí Kutné Hory (+30 km). Delší dojezdová vzdálenost je na individuální domluvě.&nbsp;Otevírání zamknutých aut a výroba auto klíčů v okolí měst Kutná Hora, Čáslav, Kolín, Golčův Jeníkov, Pardubice, Přelouč, Chvaletice, Lázně Bohdaneč, Heřmanův Městec, Chrudim, Třemošnice, Ronov nad Doubravou, Chotěboř, Havlíčkův Brod, Světlá nad Sázavou, Ledeč nad Sázavou, Zruč nad Sázavou, Uhlířské Janovice, Vlašim, Benešov, Kostelec nad Černými lesy, Český Brod, Poděbrady, Nymburk, Městec Králové, Chlumec nad Cidlinou.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" content={"<span style=\"color: var(--color-blend--50);\"><span style=\"font-weight: bold;\">FAKTURAČNÍ ÚDAJE<br></span>Michal Setnička<br>Na Výsluní 757<br>285 22 Zruč nad Sázavou<br>IČ: 09749608<br>Neplátce DPH</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}